import React, { Component } from "react";
import axios from "axios";

class Main extends Component {
    constructor(props){
        super(props);
        this.state = {
            contents: [],
            sliced: [],
            language: '',
            page: 0
        }
        this.contentRef = React.createRef();
    }
    componentDidMount() {
        this.setState({language: this.props.language});
        window.addEventListener("scroll", this.handleScroll.bind(this));
    }
    
    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll.bind(this));
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevState.language != this.props.language){
            this.setState({
                language: this.props.language
            })
            this.getData(this.props.language);
            window.scrollTo(0, 0)
            return ;
        }
    }
    
    getData = (language) => {
        axios.post('https://api.diplomasivakfi.com/api/turkeyinfo/get-content', {
            language: language,
        }).then(async res => {
            const data = res.data.data;
            this.setState({
                contents: data,
                page: 0,
                sliced: data.slice(0, 10),
                language: language
            });
        })
    }

    handleScroll = async () => {
        let container_h = this.contentRef.current.clientHeight*0.9;
        let scroll_h = window.pageYOffset + window.innerHeight;
        if(container_h < scroll_h){
            let new_page = await this.state.page + 1;
            let new_sliced = await this.state.contents.slice(new_page*10, (new_page+1)*10);
            this.setState({
                sliced: this.state.sliced.concat(new_sliced),
                page: new_page
            })
        }
    }
    render(){
        return (
            <main>
                <div className="container" ref={this.contentRef}>
                    {this.state.sliced.map((content, index) => {
                        return (
                            <div key={index} className="content">
                                <img src={`https://api.diplomasivakfi.com/turkeyinfo_image/${content.image}`}/>
                                <div className={"description " + (this.state.language == 'ar' ? 'align-right' : '' )}>{content.content}</div>
                            </div>
                        )
                    })}
                </div>
            </main>
        )
    }
}

export default Main;