import React, { useState } from 'react';
import Header from './header';
import '../assets/scss/style.scss';
import Main from './main';

const Home = () => {
  const [language, setLanguage] = useState('en');
  return (
    <div>
      <Header
        changedLanguage={e => {setLanguage(e)}}
      />
      <Main
        language={language}
      />
    </div>
  )
}
export default Home;
