import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";

const translations = {
    en: {
        wellcome: 'Wellcome'
    }
    ,
    tr: {
        wellcome: 'Hoşgeldiniz'
    }
}
const resources = {
    en: {
      translation: translations.en
    },
    tr: {
      translation: translations.tr
    }
};

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        resources,
        lng: "tr",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;