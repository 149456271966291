import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import DropdownLanguage from '../translation/switcher';

class Header extends Component {
  constructor(props){
    super(props);
    this.state = {
      loaded: false,
      language: '',
    }
  }

  componentDidMount(){
    setTimeout(() => {
      this.setState({
        loaded: true
      })
    }, 1800);
  }

  render(){
    const {t} = this.props;
    return (
      <header className={this.state.loaded ? 'loaded' : ''}>
        <div className="logo-body">
          <svg className="logo-circle">
            <circle cx="120" cy="120" r="103" strokeWidth="16" fillOpacity="0" />
          </svg>
          <div className='logo'>
            <span>TURKIYE</span>
            <span>INFO</span>
          </div>
        </div>
        <DropdownLanguage
          changedLanguage={e => {this.props.changedLanguage(e)}}
        />
        {/* {t('wellcome')} */}
      </header>
    );
  }
}

export default withTranslation()(Header);
