import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Flag from "../assets/flag";

const countries = [
    {
        image: 'tur',
        language: 'tr'
    },
    {
        image: 'gbr',
        language: 'en'
    },
    {
        image: 'fra',
        language: 'fr'
    },
    {
        image: 'sau',
        language: 'ar'
    },
    {
        image: 'isr',
        language: 'he'
    },
    {
        image: 'ita',
        language: 'it'
    },
    {
        image: 'rus',
        language: 'ru'
    },
];

const DropdownLanguage = (props) => {
    const { i18n } = useTranslation();
    const [ list, setList ] = useState([]);
    const [ activeLanguage, setActiveLanguage ] = useState('gbr');
    const list_update = async () => {
        const language = i18n.language;
        let filtered_list = [];
        await countries.forEach(country => {
            if(country.language != language){
                filtered_list.push({
                    title: <Flag country={country.image} />,
                    event: e => handleLangChange(country.language),
                    language: country.language
                })
            }else {
                setActiveLanguage(country.image)
            }
        })
        // const switchable = full_list.filter(country => country.language != language)
        setList(filtered_list)
    }
    useEffect(() => {
        const def_language = localStorage.getItem('language') == 'null' ? 'en' : localStorage.getItem('language');
        handleLangChange(def_language)
    },[]);
    
    const handleLangChange = async (evt) => {
        const lang = evt;
        await i18n.changeLanguage(lang);
        await localStorage.setItem('language', lang);
        props.changedLanguage(lang)
        list_update();
    };
    return (
            <DropdownList
                icon={<Flag country={activeLanguage} />}
                list={list}
            />
        );
    };

    export default DropdownLanguage;


    export const DropdownList = (props) => {
        const [list, setList] = useState(null);
        const [showPanel, setShowPanel] = useState(false);

        const listRef = useRef(null);
        useEffect(() => {
            prepareList();
        }, [props.list])

        const handleClickOutside = (event) => {
            if (listRef.current && event.target instanceof Node && !listRef.current.contains(event.target)) {
                setShowPanel(false);
            }
        }

        const handleClick = () => {
            setShowPanel(!showPanel);
        }

        const prepareList = async () => {
            var items = props.list;
            var item_json = await items.map((item, index) => {
                return  <a key={index} role="button" onClick={item.event}>
                            {item.title}
                        </a>
            })
            setList(item_json);
        }

        useEffect(() => {
            prepareList();
            document.addEventListener('click', handleClickOutside);
            return () => {
                document.removeEventListener('click', handleClickOutside);
            };
        }, [])

        return (
            <div className={"dd-list " + (showPanel ? 'opened ' : '') + (props.className || '')} ref={listRef}>
                <a role="button"
                    onClick={handleClick}
                >{props.icon || '' }</a>
                <div className={"dropdown-list"}>
                    {list}
                </div>
            </div>
        )
    }